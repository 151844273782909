import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import React, { Fragment } from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import "typeface-lato";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const breakpoints = createBreakpoints({});

const theme = createTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ["lato", "Arial", "sans-serif"].join(",")
  },
  palette: {
    primary: {
      main: "#487A91"
    },
    secondary: {
      main: "#99A47D"
    },
    background: {
      paper: "#ffffff",
      default: "#ffffff"
    }
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: "white",
        fontWeight: 800
      }
    },
    MuiTypography: {
      h1: {
        color: "white",
        lineHeight: 1.2,
        marginBottom: "1em",
        paddingRight: "1em",
        paddingLeft: "1em",
        fontSize: "2.625em",
        fontWeight: 400,
        [breakpoints.down("xs")]: {
          fontSize: "1.625em"
        }
      },
      h2: {
        color: "#424242",
        fontSize: "2.625em",
        paddingRight: "1em",
        paddingLeft: "1em",
        lineHeight: 1.3,
        marginBottom: "1em",
        [breakpoints.down("xs")]: {
          fontSize: "1.625em",
          fontWeight: "700"
        }
      },
      h3: {
        color: "#424242",
        fontSize: "1.625em",
        paddingRight: "1em",
        paddingLeft: "1em",
        lineHeight: 1.4,
        [breakpoints.down("xs")]: {
          fontSize: "1em",
          fontWeight: "700"
        }
      }
    }
  }
});

ReactDOM.render(
  <Fragment>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </Fragment>,
  document.getElementById("root")
);
serviceWorker.unregister();

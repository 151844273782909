const dataMizzen = [
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/mizzen/mizzen.jpg?tr=w-178,h-178,fo-auto",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/mizzen/mizzen.jpg",
    type: "image",
    title: "Mizzen",
    caption: "<h1>Mizzen</h1>"
  }
];

export default dataMizzen;

const academicData = [
  {
    img:"https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-229.jpg",
    imgLg:"https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-229.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-45.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-45.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-52.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-52.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-231.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-231.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-233.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-233.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-278.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-278.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-309.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-309.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-315.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/whitinsvillechristianschool-315.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/notredamecatholichs-91.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/notredamecatholichs-91.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/notredamecatholichs-160.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/academic/notredamecatholichs-160.jpg",
    type: "image",
    title: "",
    caption: ""
  }
];

export default academicData;

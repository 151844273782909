const academicData = [
  {
    img: "https://i.vimeocdn.com/video/1328305263-aa0e26ab58fb81de83efab2111ee0878ab64a5c73e5d788f2988a09253840328-d_295x166?r=pad",
    imgLg: "https://vimeo.com/657959406",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/3da3cbac-ca6a-4ff4-9b0b-afbafb3c7631.gif?ClientID=vimeo-core-prod&Date=1651344289&Signature=a95376d4b31467893b83d703d91865ab643070a7",
    type: "vimeo",
    title: "Riverbend School",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1085019745-9825ae1f159e404671113971bedea7f7b3b6c1faa60688887f434768c159aa3f-d_295x166",
    imgLg: "https://vimeo.com/664851440",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/a65f3adb-a79e-4965-a912-77def99d5f99.gif?ClientID=vimeo-core-prod&Date=1651344509&Signature=d1af47e11105e6e99351c7c0116108a79fba7428",
    type: "vimeo",
    title: "Bancroft School - Learning Lab",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1124344386-3feb0e52c1429ab89ccfae51d90f34f7a7e7550ffbf3bcdfcd12d07668fc9c51-d_295x166?r=pad",
    imgLg: "https://vimeo.com/543139452",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/a94a0ad2-0a7f-4bd7-b1ee-de789a3dffe7.gif?ClientID=vimeo-core-prod&Date=1651343803&Signature=35559b1a2bea26cd91ca78c2f274ba09480ea085",
    type: "vimeo",
    title: "Mitchell College - Spirit",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1300688375-89038c3f680ad076524f70cb8737c50286b4fc7e0cf7d53e3_295x166?r=pad",
    imgLg: "https://vimeo.com/646211293",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/b538f8a2-e810-45cc-9b8f-d9fbe586c3e9.gif?ClientID=vimeo-core-prod&Date=1620239876&Signature=f6c5592c3f2c13afbe5e730bacdc6e0a7252b2d8",
    type: "vimeo",
    title: "Holy Cross - Giving Tuesday Ad Spot",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1157846565-6e822b79f977436f086cb3fecbf8faca44ecef2ed7755921a4070ce09d199b26-d_295x166?r=pad",
    imgLg: "https://vimeo.com/560115780",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/55bc55df-eb2a-4955-8742-28af65a73799.gif?ClientID=vimeo-core-prod&Date=1651343633&Signature=db75e70eeff87db4843f1728d802c6b9036c7d5d",
    type: "vimeo",
    title: "St. Joesph's College - The Mission",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1294019000-3fc928515090f626f592815305dababd8da72d6a8c787051f_295x166?r=pad",
    imgLg: "https://vimeo.com/641638086",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/8d665955-0d0e-4e94-843f-9924a261e998.gif?ClientID=vimeo-core-prod&Date=1651343543&Signature=f93e861bb73d08604d9b5cc15c16a4499ca79b30",
    type: "vimeo",
    title: "St. Joesph's College - Awards",
    vendor: "",
    project: "Click to watch"
  },

  {
    img: "https://i.vimeocdn.com/video/1085019745-9825ae1f159e404671113971bedea7f7b3b6c1faa60688887f434768c159aa3f-d_295x166",
    imgLg: "https://vimeo.com/523837191",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/b538f8a2-e810-45cc-9b8f-d9fbe586c3e9.gif?ClientID=vimeo-core-prod&Date=1620239876&Signature=f6c5592c3f2c13afbe5e730bacdc6e0a7252b2d8",
    type: "vimeo",
    title: "Whitinsville Christian School",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/812586091-1ed86340f2da531e6af51761dda69be311a51c5f1ffa7c5d17d44cecd230936e-d_295x166",
    imgLg: "https://vimeo.com/358833057",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/75e5129e-f1cd-476c-9d35-1febd5f0ff89.gif?ClientID=vimeo-core-prod&Date=1620242976&Signature=40e0990f934118a49c4d4f54bd8de6a50c49f993",
    type: "vimeo",
    title: "Middlesex School: Arts",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1051797383-44b094be332e38839b7b939e5f3600dc9875488fc22f42d827836b013afe141d-d_295x166",
    imgLg: "https://vimeo.com/508192459",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/1f93427d-a706-402a-b042-57858258ac9a.gif?ClientID=vimeo-core-prod&Date=1620242319&Signature=25c33357d68aa7cc4509726cbb5990563fcb18e8",
    type: "vimeo",
    title: "Riverbend School",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1010298811-27b08caf00a6ac0f64bf058894487e041a1e9bca6841f29dfdc20c38951f68b2-d_295x166",
    imgLg: "https://vimeo.com/488198878",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/84d08a7d-1046-45f9-85e5-77710b61b298.gif?ClientID=vimeo-core-prod&Date=1620242705&Signature=e840496ef80803d19966d574b6b9ba6861bedf4b",
    type: "vimeo",
    title: "College of the Holy Cross",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/812586962-126711babbf75f23107d134f3faf8f795f6eed8d7d1f0b1395278c1f69e743de-d_295x166",
    imgLg: "https://vimeo.com/358836114",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/9aff1e68-7608-44cb-9d7d-d9a839e9ecb7.gif?ClientID=vimeo-core-prod&Date=1620242785&Signature=cf1d574b489e52e277078361779be0d6b9b7908e",
    type: "vimeo",
    title: "MIT Slone Expert Series",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/812586443-5e7fb06795f65cfc2d04ca51e455c1ab045e5d0c1ea465ed0b03a2d28a87f741-d_295x166",
    imgLg: "https://vimeo.com/358834234",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/d80a4c97-d3d2-416a-bf70-30cffdddbcdc.gif?ClientID=vimeo-core-prod&Date=1620242876&Signature=6fff2f48a47783aae0dae8c12dda12b757deaa40",
    type: "vimeo",
    title: "The Dillon Center",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1345648176-55dc1540c0809ebba71bf395d11768bce07afb0156229f9a152f044146bd9c96-d_295x166?r=pad",
    imgLg: "https://vimeo.com/490932324",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/8a6e457f-a7e8-455a-b131-05f7361ecfac.gif?ClientID=vimeo-core-prod&Date=1620242631&Signature=dee486b5fc867dfc91d80d94af122c15cb19036c",
    type: "vimeo",
    title: "Middlesex School: Accepted Students",
    vendor: "",
    project: "Click to watch"
  }
];

export default academicData;

const dataChris = [
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/chris/chris-mackos.jpg?tr=w-178,h-178,fo-auto",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/chris/chris-mackos.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: "<h1>Chris Mackos</h1>Chris is a graduate of Fitchburg State University's Communications Media program with a concentration in film and video production. Chris began with a background of freelance work for companies like Comcast SportsNet, McElroy Films and Wales Films before working for DigiNovations as a Video Production Associate. He honed his skills in various aspects of production as a Director of Photography and Production Manager before becoming a Producer in 2017. Well rounded, his skills range from IT management in a production environment to cinematography, editing, producing and photography. Loving a good camping adventure, you'll find Chris exploring new places and activities with his wife Sarah and two dogs Franklin and Manny."
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/308636_10151480536155674_1970939081_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/308636_10151480536155674_1970939081_n.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/19701997_10154927639890674_2455552488330626068_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/19701997_10154927639890674_2455552488330626068_n.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/20615749_10155033374465674_3223223785728027638_o.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/20615749_10155033374465674_3223223785728027638_o.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/25659928_10155410649735674_5612469480800758649_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/25659928_10155410649735674_5612469480800758649_n.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/38712035_10102920892235585_4050978772373995520_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/38712035_10102920892235585_4050978772373995520_n.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/43522915_10157236784329523_1569062891666014208_o.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/43522915_10157236784329523_1569062891666014208_o.jpg",
    type: "image",
    title: "Chris Mackos",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/51931700_2556930197655730_3216513038177796096_o.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/51931700_2556930197655730_3216513038177796096_o.jpg",
    type: "image",
    title: "Chris",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/54435731_10156446362740674_4239658472393670656_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/54435731_10156446362740674_4239658472393670656_n.jpg",
    type: "image",
    title: "Chris",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/60960224_10157811703369523_6644668630044770304_o.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/60960224_10157811703369523_6644668630044770304_o.jpg",
    type: "image",
    title: "Chris",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/61930244_1577144025768527_2479518928179560448_o.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/61930244_1577144025768527_2479518928179560448_o.jpg",
    type: "image",
    title: "Chris",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/67550644_10156761985125674_5249905853531684864_n.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/chris/67550644_10156761985125674_5249905853531684864_n.jpg",
    type: "image",
    title: "Chris",
    caption: ""
  }
];

export default dataChris;

const testimonialSteps = [
  {
    testimonial:
    "Whit and his team have helped us create a national grassroots movement through their videos and film. No matter the time of day (or night) they produce the highest quality of content and are always available. They are professionals in their thoughtful approach to interviews and expertise in video construction and editing. We would not be where we are now without Endeavor Films!",
    author: "Ilaria Santangelo",
    title: "Research Analyst & Communications",
    company: "Patient Rights Advocate"
  },
  {
    testimonial: "Endeavor films feel like a true extension of our team; that's how well we've worked together. I have hired them to work on numerous projects over the past few years, and we've reached a place where I have a high degree of trust and confidence in their abilities to capture our process and create a video that accomplishes our goals. Their team of producers have a keen eye, they understand complex products, and they have a way of making industrial manufacturing captivating.",
    author: "Kate Connors",
    title: "Marketing Manager",
    company: "CDF Corporation"
  },
  {
    testimonial: "Endeavor Films was a joy to work with. Not only did they provide wonderful video products and service, but they did so in a way that encouraged us to imagine a new standard for our in-house production work. We are grateful for this partnership with genuinely nice people committed to excellence.",
    author: "Emily F. Morris, Ph.D.",
    title: "Senior Vice President and Chief Brand Officer",
    company: "Chautauqua Institution"
  },
  {
    testimonial: "Whit and his team are extremely talented, sensitive to client needs, and really understand how to tell an engaging story. I recommend them to everyone who is looking to do video work!",
    author: "Chiara Wegener",
    title: "Chief Communications Officer",
    company: "Nellie Mae Education Foundation"
  },
  {
    testimonial: "The Endeavor team have a great sense of storytelling, visually and narratively. They are terrific creative collaborators who know that their partnership lives beyond any singular piece, and whose vision can transform individual pieces into timeless ones.",
    author: "Chad Minnich",
    title: "Director of Advancement Communications",
    company: "Holy Cross College"
  },
  {
    testimonial: "I don’t know how the Endeavor Films team did it, but they were able to distill hours of interviews and on-location footage into an amazing, high-quality video that not only tells our story in a visual, moving, and engaging way, but also is an important tool in recruiting volunteers for our organization. They really took the time to get to know our mission, understand our goals for the video, and listen to our thoughts and ideas. The filming and production were handled very professionally, and everyone we met was a pleasure to work with.",
    author: "Audrey Trieschman",
    title: "Manager of Communications",
    company: "NEADS World Class Service Dogs"
  },
  {
    testimonial:
      "Whit and his team are committed to understanding and working closely with us on the unique messaging and style of each film. He’s exceptional.",
    author: "Doug Price",
    title: "Dean of Admissions and Financial Aid",
    company: "Middlesex School"
  },
  {
    testimonial:
      "They are professional, creative - they love what they do. ... Communication is constant and deadlines are always met.",
    author: "Nicole LoCurto",
    title: "Director, Marketing",
    company: "St. John’s University"
  },
  {
    testimonial:
      "The folks at Endeavor films have a keen sense of what makes each campus unique and instinctively know how to engage students, faculty and administration to render work that is fresh and authentic.",
    author: "Steve Keating",
    title: "President",
    company: "Keating and Associates"
  },
  {
    testimonial:
      "They are gifted in tuning into exactly what you are after and then bringing it to life. I couldn’t recommend this production company more highly!",
    author: "Bob Childs",
    title: "Artistic Director",
    company: "Childsplay"
  },
  {
    testimonial:
      "Working with Endeavor, you know that you are in the hands of experts - experts who care about the people they film and who take pride in what they do.",
    author: "Ivo Futselaar",
    title: "Vice President, Talent Manager",
    company: "TD Bank"
  },
  {
    testimonial:
      "Endeavor Films has expressed our mission exquisitely and with emotional resonance. Their work in helping us to engage and enlarge awareness of our organization in our community and beyond, has made all the difference!",
    author: "Suzanne Pennoyer Snyder",
    title: "Executive Director",
    company: "Sail Beyond Cancer"
  }
];

export default testimonialSteps;

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import styled from "styled-components";
import About from "./about/about";
import Clients from "./clients/clients";
import Contact from "./contact/contact";
import Film from "./film/film";
import Footer from "./footer/footer";
import Mission from "./mission/mission";
import Nav from "./navigation";
import Photography from "./photography/photography";

function App() {
  return (
    <Container id="top">
      <Video>
        <iframe
          src="https://player.vimeo.com/video/805567186?background=true"
          framebrder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="Video background"
          rel="preconnect"
        ></iframe>
      </Video>
      <header className="masthead">
        <Nav />
        <Value>
          <Grid container justify="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h1">
                Endeavor Films
                <br />
                <b>We bring vision to your brand</b>
              </Typography>
            </Grid>
          </Grid>
        </Value>
        <svg
          className="angleup"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            fill="hsla(199, 34%, 43%, 1)"
            fillRule="evenodd"
            d="M0 68h1439.5V0L719.7 68 0 0c-.7 0-.7 14 0 68z"
          />
        </svg>
      </header>
      <SectionRow id="mission">
        <Mission />
        <svg
          className="angle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            fill="#ffffff"
            fillRule="evenodd"
            d="M0 68h1439.5V0L719.7 68 0 0c-.7 0-.7 14 0 68z"
          />
        </svg>
      </SectionRow>
      <SectionRow id="film">
        <Film />
      </SectionRow>
      <SectionRow id="clients">
        <Clients />
      </SectionRow>
      <SectionRow id="photography">
        <Photography />
      </SectionRow>
      <SectionRow id="about">
        <About />
      </SectionRow>
      <SectionRow id="contact">
        <Contact />
      </SectionRow>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  header.masthead {
    position: relative;
    width: 100%;
    height: 46.5625em;
    z-index: 5;
    nav {
      height: 4.25em;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .angleup {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .angle {
    position: absolute;
    bottom: 2em;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  button {
    margin-top: 0;
    z-index: 2;
    position: relative;
  }
`;

const Value = styled.div`
  position: absolute;
  width: 100%;
  bottom: 8.25em;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 3;
  transition: 0.2s ease;
  text-align: center;
  h1,
  h3 {
    margin: 0 0 0.5em;
    color: #fff;
  }
  h1 {
    text-transform: uppercase;
    font-weight: 800;
    b {
      font-weight: 400;
      text-transform: capitalize;
    }
  }
`;

const Video = styled.div`
  height: 46.5625em;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 4;
    background: linear-gradient(
      hsl(0, 0%, 0%) 0%,
      hsla(0, 0%, 0%, 0.738) 19%,
      hsla(0, 0%, 0%, 0.541) 34%,
      hsla(0, 0%, 0%, 0.382) 47%,
      hsla(0, 0%, 0%, 0.278) 56.5%,
      hsla(0, 0%, 0%, 0.194) 65%,
      hsla(0, 0%, 0%, 0.126) 73%,
      hsla(0, 0%, 0%, 0.075) 80.2%,
      hsla(0, 0%, 0%, 0.042) 86.1%,
      hsla(0, 0%, 0%, 0.021) 91%,
      hsla(0, 0%, 0%, 0.008) 95.2%,
      hsla(0, 0%, 0%, 0.002) 98.2%,
      hsla(0, 0%, 0%, 0) 100%
    );
    opacity: 0.6;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-size: cover;
    height: 100%;
  }
  iframe {
    box-sizing: border-box;
    border: 0;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
    z-index: 1;
  }
`;
const SectionRow = styled.div`
  box-sizing: border-box;
  padding: 2.25em 0 2.25em;
  position: relative;
  text-align: center;
  .bigpicture {
    img {
      width: 288px;
    }
  }
`;

export default App;

const corporateData = [
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-24.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-24.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-53.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-53.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-127.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-127.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-133.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-133.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-152.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_florida-152.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-34.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-34.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-110.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-110.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-171.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-171.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-244.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-244.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-260.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_evp_maine_boston-260.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i8658.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i8658.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i9350.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i9350.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i9369.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/td_v4i9369.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-108.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-108.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-141.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-141.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-166.jpg?&w=754",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/corporate/tdtreedays_phillypa_051719-166.jpg",
    type: "image",
    title: "",
    caption: ""
  }
];

export default corporateData;

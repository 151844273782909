import React from "react";
// eslint-disable-next-line
import { BigPicture, BigPictureGallery } from "react-bigpicture";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import corporateData from "./data_corporate";
import academicData from "./data_academic";
import nonprofitData from "./data_nonprofit";
import LazyLoad from "react-lazyload";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: 750
  },
  title: {
    color: theme.palette.primary.light
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <LazyLoad>
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Photography</Typography>
          </Grid>
          <Grid item xs={12}>
            <SectionStyle>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                aria-label="full width tabs example"
                centered={true}
              >
                <Tab label="Corporate" {...a11yProps(0)} />
                <Tab label="Academic" {...a11yProps(1)} />
                <Tab label="Non Profit" {...a11yProps(2)} />
              </Tabs>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <div className="alpha">
                    <BigPictureGallery loop>
                      {corporateData.map(tile => (
                        <img
                          src={tile.img}
                          zoomedsrc={tile.imgLg}
                          caption={tile.caption}
                          data-bp={tile.img}
                          alt={tile.title}
                          loading="lazy"
                        />
                      ))}
                    </BigPictureGallery>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <div className="alpha">
                    <BigPictureGallery loop>
                      {academicData.map(tile => (
                        <img
                          src={tile.img}
                          zoomedsrc={tile.imgLg}
                          caption={tile.caption}
                          data-bp={tile.img}
                          alt={tile.title}
                          loading="lazy"
                        />
                      ))}
                    </BigPictureGallery>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <div className="alpha">
                    <BigPictureGallery loop>
                      {nonprofitData.map(tile => (
                        <img
                          src={tile.img}
                          zoomedsrc={tile.imgLg}
                          caption={tile.caption}
                          data-bp={tile.img}
                          alt={tile.title}
                          loading="lazy"
                        />
                      ))}
                    </BigPictureGallery>
                  </div>
                </TabPanel>
              </SwipeableViews>
            </SectionStyle>
          </Grid>
        </Grid>
      </div>
    </LazyLoad>
  );
}

const SectionStyle = styled.div`
  .alpha {
    img:not(:first-of-type) {
      display: none;
    }
    img {
      border-radius: 0.5em;
      overflow: hidden;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
      height: 11.125em;
    }
  }
`;

const dataWhit = [
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/whit/whit-wales.jpg?tr=w-178,h-178,fo-auto",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/whit/whit-wales.jpg",
    type: "image",
    title: "Whit Wales",
    caption:
      "<h1>Whit Wales</h1>Whit’s lifelong background in education - performance and story creation - have come to shape and define his purpose. The desire to create a production company dedicated to serving mission driven institutions is what inspired the launch of Endeavor Films. Outside of work, Whit spends weekends on the water with his partner, Suzanne, providing sails for those battling cancer. Whit is a graduate of Phillips Exeter, Amherst College and earned an MFA in Acting at the Meadows School for the Arts at Southern Methodist University."
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/healingwinds_071517_sail1-18.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/healingwinds_071517_sail1-18.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-5.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-5.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-80.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-80.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-94.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-94.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-135.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/naya4th_birthdayparty_selects-135.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/suzanne_elf.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/suzanne_elf.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/suzannegypsywind.jpg?tr=w-288",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/staff/whit/suzannegypsywind.jpg",
    type: "image",
    title: "Whit Wales",
    caption: ""
  }
];

export default dataWhit;

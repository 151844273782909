import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MobileStepper from "@material-ui/core/MobileStepper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import React from "react";
import SVG from "react-inlinesvg";
import LazyLoad from "react-lazyload";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import styled from "styled-components";
import AuroraLogo from "./aurora-logo.svg";
import BabsonLogo from "./babson-logo.svg";
import BancroftLogo from "./bancroft-logo.svg";
import CDFLogo from "./cdf-logo.svg";
import testimonialSteps from "./data.js";
import DillonLogo from "./dillon-logo.svg";
import EvolvLogo from "./evolv-logo.svg";
import KeatingLogo from "./keating-logo.svg";
import MitLogo from "./mit-logo.svg";
import MiddlesexLogo from "./mx-logo-white.svg";
import NetscoutLogo from "./netscout-logo.svg";
import RedhatLogo from "./redhat-logo.svg";
import RiverbendLogo from "./riverbend-logo.svg";
import SailLogo from "./sail-logo.svg";
import SjcLogo from "./sjcny-logo.svg";
import TdbankLogo from "./Td-bank-logo.svg";
import TrinityLogo from "./trinity-logo.svg";
import WhitinsvilleLogo from "./whitinsville-christian-school-logo.svg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = testimonialSteps.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <LazyLoad>
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <ClientStyle>
              <Value>
                <svg
                  className="angle"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 68"
                >
                  <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M1440 0L721.4 68 0 0z"
                  />
                </svg>
                <Typography variant="h2">Testimonials</Typography>
                <iframe
                  src="https://player.vimeo.com/video/360559161?background=true"
                  framebrder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                  title="Video background"
                  rel="preconnect"
                  loading="lazy"
                ></iframe>
              </Value>
              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                interval="12000"
                enableMouseEvents
              >
                {testimonialSteps.map((step, index) => (
                  <Steps key={step.testimonial}>
                    <Voice>
                      <Typography variant="h3">
                        "{testimonialSteps[activeStep].testimonial}"
                      </Typography>
                      <Typography>
                        {testimonialSteps[activeStep].author},{" "}
                        {testimonialSteps[activeStep].title},{" "}
                        {testimonialSteps[activeStep].company}
                      </Typography>
                    </Voice>
                    {Math.abs(activeStep - index) <= 2 ? <Video></Video> : null}
                  </Steps>
                ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                nextButton={
                  <IconButton
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    aria-label="left"
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                }
                backButton={
                  <IconButton
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    aria-label="right"
                  >
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                  </IconButton>
                }
              />
              <Logos>
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={BabsonLogo}
                  title="Babson Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={DillonLogo}
                  title="Dillon Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={EvolvLogo}
                  title="Evolv Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={KeatingLogo}
                  title="Keating Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={MiddlesexLogo}
                  title="Middlesex Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={MitLogo}
                  title="MIT Sloan Schhol Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={RedhatLogo}
                  title="RedHat Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={RiverbendLogo}
                  title="Riverbend Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={SailLogo}
                  title="Sail Beyond Cancer Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={SjcLogo}
                  title="St.Joseph's College Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={BancroftLogo}
                  title="Bancroft School"
                />
                                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={AuroraLogo}
                  title="Aurora Institute"
                />
                                                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={TrinityLogo}
                  title="Trinity"
                />
                                                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={NetscoutLogo}
                  title="Netscout"
                />
                                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={TdbankLogo}
                  title="TD Bank Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={WhitinsvilleLogo}
                  title="Whitinsville Christian School Logo"
                />
                <SVG
                  cacheRequests={true}
                  onLoad={(src, hasCache) => console.log(src, hasCache)}
                  preProcessor={code =>
                    code.replace(/fill=".*?"/g, 'fill="#fff"')
                  }
                  src={CDFLogo}
                  title="CDF Packaging"
                />
              </Logos>
              <svg
                className="angleup"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 68"
              >
                <path
                  fill="#ffffff"
                  fillRule="evenodd"
                  d="M0 68h1439.5V0L719.7 68 0 0c-.7 0-.7 14 0 68z"
                />
              </svg>
            </ClientStyle>
          </Grid>
        </Grid>
      </div>
    </LazyLoad>
  );
}

const ClientStyle = styled.div`
  position: relative;
  height: 47.125em;
  background-color: #99a47d;
  overflow: hidden;
  .angleup {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .angle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }
  .MuiMobileStepper-root,
  .MuiPaper-root {
    background: none;
  }
  .MuiMobileStepper-root {
    button {
      position: absolute;
      top: 40%;
      color: rgba(255, 255, 255, 0.8);
      .MuiSvgIcon-root {
        font-size: 4.25em;
      }
      &:first-child {
        left: 2.25em;
      }
      &:last-child {
        right: 2.25em;
      }
    }
  }
  .MuiMobileStepper-dots {
    position: absolute;
    bottom: 13rem;
    width: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media (min-width: 220px) and (max-width: 560px) {
    .MuiMobileStepper-root {
      button {
        .MuiSvgIcon-root {
          font-size: 2em !important;
        }
        &:first-child {
          left: -0.5em;
        }
        &:last-child {
          right: -0.5em;
        }
      }
    }
    .MuiMobileStepper-dots {
      bottom: 12rem;
    }
  }
`;
const Steps = styled.div`
  position: relative;
  height: 47.125em;
`;

const Video = styled.div`
  height: 47.125em;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
`;

const Value = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  h2 {
    margin: 3.625em 0 0.5em;
    line-height: 1.4;
    color: #fff;
  }
  h3,
  .MuiTypography-body1 {
    color: #fff;
  }
  button {
    margin-top: 1em;
  }
  iframe {
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;
    z-index: 0;
    filter: opacity(0.2) grayscale(0.9);
  }
  @media (min-width: 220px) and (max-width: 560px) {
    h2 {
      margin: 4.25em 0 0.5em;
    }
  }
`;
const Voice = styled.div`
  position: absolute;
  top: 13rem;
  width: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 4;
  transition: 0.2s ease;
  text-align: center;
  h2 {
    margin: 0 0.5em 0.5em;
    line-height: 1.4;
    color: #fff;
  }
  h3 {
    color: #fff;
  }
  .MuiTypography-body1 {
    color: #fff;
    font-size: 0.825em;
    padding: 0 1em;
  }
  button {
    margin-top: 1em;
  }
  @media (min-width: 220px) and (max-width: 560px) {
    width: 96%;
    top: 10rem;
    h3 {
      font-weight: 400;
    }
  }
`;
const Logos = styled.div`
  text-align: center;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  width: 90%;
  transform: translate(-50%, 0);
  svg {
    margin: 1em;
    height: 2.65em;
  }
  @media (min-width: 220px) and (max-width: 800px) {
    bottom: 1rem;
    svg {
      height: 1.5rem;
    }
  }
`;

export default SwipeableTextMobileStepper;

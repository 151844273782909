const dataFrancisco = [
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/francisco-santiago.jpg?tr=w-178,h-178,fo-auto",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/francisco-santiago.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption:
      "<h1>Francisco Santiago</h1><p>Francisco Santiago is a bilingual English/Spanish speaker and has been a filmmaker for over 10 years. He has done work locally and internationally as a cinematographer. He has filmed documentaries in Africa, South America, the Caribbean, and across the US. Also, he has worked for TV shows such as Trading Spaces and multiple movie sets for independent films and commercial films. He has directed music videos that have been seen on MTV and other TV outlets. He grew up in Boston MA where he attend the University of Massachusetts and received a BA in Community Media and Technology. On his free time he goes for long walks listens to philosophy podcast and loves to go out dancing and hang with family and friends."
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6810.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6810.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6812.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6812.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6818.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6818.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6821.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6821.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6822.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6822.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6823.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6823.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6827.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6827.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6831.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6831.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6835.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6835.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6836.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6836.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6837.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/francisco/img_6837.jpg",
    type: "image",
    title: "Francisco Santiago",
    caption: ""
  }
];

export default dataFrancisco;

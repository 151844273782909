const corporateData = [
  {
    img:
      "https://i.vimeocdn.com/video/1330129449-f5eeac285e0f95babb206c6d9d4f276ecca8c1068b8e653b5f891e447ad2cf9d-d_295x166?r=pad",
    imgLg: "https://vimeo.com/658648595",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/5fa70853-df15-4abc-906c-578f140b9f9c.gif?ClientID=vimeo-core-prod&Date=1651332213&Signature=833a6a378a2e037ac4ba6d895b5eca371c5a1fea",
    type: "vimeo",
    title: "CDF",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1228539947-01db4d097563944393382713db23b15ad9713d824ef68247ac86a17d152ea6e9-d_295x166?r=pad",
    imgLg: "https://vimeo.com/594869061",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/b32b7bf2-4593-4acc-8d66-7a45bc814eaa.gif?ClientID=vimeo-core-prod&Date=1651332129&Signature=7ddf410c1162021dca6e98890c77e2c2ebc546bf",
    type: "vimeo",
    title: "TD Bank",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1354333021-9a0e7b658b5b8657810a97ed157fbd084ed1fe70c0578401d0fab19eecc4a31b-d_295x166?r=pad",
    imgLg: "https://vimeo.com/668820844",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/8f1a7e81-bca0-410d-a889-979c9997f67c.gif?ClientID=vimeo-core-prod&Date=1651332018&Signature=b07dfc3c7c806473e6980ab40b0416f4fb21804b",
    type: "vimeo",
    title: "Trinity",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1390028163-9f37fe3bb1ce292a31a043af5462a6f649c2181ea421d075d9d288a2e19e0fa4-d_295x166?r=pad",
    imgLg: "https://vimeo.com/685965400",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/27c116e8-2919-4fbc-a8ad-c67aa8eda601.gif?ClientID=vimeo-core-prod&Date=1651331181&Signature=046446c2b440418af3bf899093051654536ea8cd",
    type: "vimeo",
    title: "TD Bank",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1128745113-768661532b2ff46fd2f4e0d98fd53b18bc1c5cede61fe41c21eacd73e0d81402-d_295x166?r=pad",
    imgLg: "https://vimeo.com/545200513",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/6258dfc4-2df6-40af-8684-8400fbf4c2db.gif?ClientID=vimeo-core-prod&Date=1651331839&Signature=63d9e3a37fca386bc1d7fc39134f73115be866e0",
    type: "vimeo",
    title: "Trinity",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1390016475-88c4096ef2655d19d299ff0fbdfdf50699a09b57da0f8ea8262f68de87341526-d_295x166?r=pad",
    imgLg: "https://vimeo.com/362330236",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/248dd746-ec09-470e-a196-76dab1d6c61c.gif?ClientID=vimeo-core-prod&Date=1651331531&Signature=5e3df05102d6c4d27b93cac50c623a4d025dd4fa",
    type: "vimeo",
    title: "TD Bank - Ceaser",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/1416628541-5ac5a222d6ed1e77f7d2a0aeb26a73c0702ec9326de60a404ae1f95991e014d5-d_1280",
    imgLg: "https://vimeo.com/454463211",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/37692dde-a1d4-486e-97ed-79d880fda887.gif?ClientID=vimeo-core-prod&Date=1650381338&Signature=d07aed80a9df1de897e68ba37f7f70d42e4f6aec",
    type: "vimeo",
    title: "Red Hat",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/812585725-bd607d4dd678137896078fbcb9e6f4248cd2602f1baa7537cc6f943f5a7c2dea-d_295x166",
    imgLg: "https://vimeo.com/358837138",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/c01f360d-5c2f-4a69-b8a7-1b0289cbe219.gif?ClientID=vimeo-core-prod&Date=1620235580&Signature=db6f71e1ac56014f97a7d90c26caa023fa4c13ba",
    type: "vimeo",
    title: "TD Bank - Memorial",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/812584853-3ed78da72f5c4cb92f7ae7adbb3b572144230807faf3a585936bdfdeef5d98db-d_295x166",
    imgLg: "https://vimeo.com/358838029",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/62609bab-465a-40fb-97e4-b37d00a8276f.gif?ClientID=vimeo-core-prod&Date=1620235506&Signature=9a6fc94f6e1c004e96766324f6a2d6af8f678fbf",
    type: "vimeo",
    title: "TD Bank",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/812584180-7e1607ca088443d99f4c2a92356761e55172593b2f8d37fcc7997c5569ecb9bb-d_1280",
    imgLg: "https://vimeo.com/358839234",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/10e2ff30-3898-4783-822e-09c45986e454.gif?ClientID=vimeo-core-prod&Date=1650382982&Signature=4ce0ce495642117b99b4a061476b0447c3bc4136",
    type: "vimeo",
    title: "Central Rock Gym",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/812583872-33229f3795c869ced311622b5e551bb6185742c489e86124329dfb0d20c7e9c9-d_295x166",
    imgLg: "https://vimeo.com/358840056",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/c068a92e-674e-46f9-9702-da89637ce136.gif?ClientID=vimeo-core-prod&Date=1650383286&Signature=1b4552fcfcbcaac1be4a4d84f5bea19b23db7d9f",
    type: "vimeo",
    title: "CDF",
    vendor: "",
    project: "Click to watch"
  }
];

export default corporateData;

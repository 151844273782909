const nonprofitData = [
  {
    img: "https://i.vimeocdn.com/video/973818510-d616872ee88cea0a7098d8494c583932d5b81e83c6d7d28c392b378af8e4cca9-d_295x166?r=pad",
    imgLg: "https://vimeo.com/467410143",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/2375705b-9e1a-4953-a7b2-3f9d4a95d110.gif?ClientID=vimeo-core-prod&Date=1651344579&Signature=9b8dd9ab38223bc196e2096f6b0b003ab6d003e8",
    type: "vimeo",
    title: "Santoro Family",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1319931275-cda88110b4c86f3851dcabdf842da63551bd8f865bc0ce3b5753080783d420b2-d_295x166?r=pad",
    imgLg: "https://vimeo.com/653974934",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/1d42e9fc-4469-4831-af94-7986077a1a03.gif?ClientID=vimeo-core-prod&Date=1651344368&Signature=4789fa730eeaa7ebcac440921d62fede2c940876",
    type: "vimeo",
    title: "Sail Beyond Cancer 2021",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/812588368-5221f35900dae4dce3f5519c6f0e9ca2e8932b91cfbbfa8e2b9b69ff1cd47aed-d_295x166",
    imgLg: "https://vimeo.com/358830048",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/d2c006f4-62b9-402d-b818-234655647493.gif?ClientID=vimeo-core-prod&Date=1620243897&Signature=fa07e1eda877d9b8e71be5b012d68d04c1a59e52",
    type: "vimeo",
    title: "Patient Rights Advocate, Oklahoma",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/812587589-ff129f407fe7eb5cb2dae9c001779d9adb7effd1c6bb19534482dc0fe4fe8ed6-d_295x166",
    imgLg: "https://vimeo.com/358828942",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/8fcd8230-89a8-4f53-95b7-219f0f72ecd1.gif?ClientID=vimeo-core-prod&Date=1620243797&Signature=37644af721209a22c5612c0fbf4c69189f13175d",
    type: "vimeo",
    title: "Patient Rights Advocate, Pennsylvaina",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:"https://i.vimeocdn.com/video/812565095-b774b8ab64d142cf63d599a4b2b9a796927d6718726b39a082d6ffa734e08d3d-d_295x166",
    imgLg: "https://vimeo.com/358830971",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/271df628-e738-4386-907b-342bd82a84a5.gif?ClientID=vimeo-core-prod&Date=1620243744&Signature=ae9c82e1890c49744cc0346b716f198cb52575b0",
    type: "vimeo",
    title: "Patient Rights Advocate, Texas",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1129645954-88d89e0ea123b0e62ed32e44982d8b896f9e160dc516c5e5e046c7e6e9de5936-d_295x166",
    imgLg: "https://vimeo.com/525104179",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/ed4047c0-cd62-44f1-9c2a-2c602ec07ee0.gif?ClientID=vimeo-core-prod&Date=1620243406&Signature=76205b831f426c7ab23aa740ee3b91f3be84c8d7",
    type: "vimeo",
    title: "DOVE, Domestic Violence Ended",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/1001621200-28a6a7116be4e0f586123a291b1f054e066868cc9eb155b5dce209b7f4a14043-d_295x166",
    imgLg: "https://vimeo.com/483250729",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/909550a5-7fe8-45a7-8935-60100dc714d0.gif?ClientID=vimeo-core-prod&Date=1620243478&Signature=49dce5e4e24d0af629cba740fe5100e2c57d584f",
    type: "vimeo",
    title: "NEADS",
    vendor: "",
    project: "Click to watch"
  },
  {
    img: "https://i.vimeocdn.com/video/997511577-4c0b94e9a3f768d60d7587acd1644c2fbf5964d244fe594b430a5b797776b8e4-d_295x166",
    imgLg: "https://vimeo.com/480843682",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/e75bf7c0-9a62-4cd4-87d7-9f051f233e4b.gif?ClientID=vimeo-core-prod&Date=1620243634&Signature=48c2135bb22a135b024ff5a5af9ee8dde9e8f38c",
    type: "vimeo",
    title: "Sail Beyond Cancer",
    vendor: "",
    project: "Click to watch"
  }
];

export default nonprofitData;

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
// eslint-disable-next-line
import { BigPictureGallery } from "react-bigpicture";
import LazyLoad from "react-lazyload";
import styled from "styled-components";
import staffDataChris from "./dataChris.js";
import staffDataFrancisco from "./dataFrancisco.js";
import staffDataJustin from "./dataJustin.js";
import staffDataMizzen from "./dataMizzen.js";
import staffDataWhit from "./dataWhit.js";

export default function About() {
  return (
    <LazyLoad>
      <div>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Who We Are</Typography>
            <Typography>
              Award-winning, passionate, visual storytellers who strive to
              provide the highest quality of work in service of you and
              all of which you are justifiably proud.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SectionStyle>
              <div className="diamond">
                <div className="alpha">
                  <BigPictureGallery loop>
                    {staffDataWhit.map(tile => (
                      <img
                        src={tile.img}
                        zoomedSrc={tile.imgLg}
                        caption={tile.caption}
                        alt={tile.title}
                      />
                    ))}
                  </BigPictureGallery>
                </div>
                <div className="alpha">
                  <BigPictureGallery loop>
                    {staffDataJustin.map(tile => (
                      <img
                        src={tile.img}
                        zoomedSrc={tile.imgLg}
                        caption={tile.caption}
                        alt={tile.title}
                      />
                    ))}
                  </BigPictureGallery>
                </div>
                <div className="alpha">
                  <BigPictureGallery loop>
                    {staffDataChris.map(tile => (
                      <img
                        src={tile.img}
                        caption={tile.caption}
                        zoomedSrc={tile.imgLg}
                        data-bp={tile.img}
                        alt={tile.title}
                      />
                    ))}
                  </BigPictureGallery>
                </div>
                <div className="alpha">
                </div>
                <div className="alpha">
                  <BigPictureGallery loop>
                    {staffDataFrancisco.map(tile => (
                      <img
                        src={tile.img}
                        zoomedSrc={tile.imgLg}
                        caption={tile.caption}
                        alt={tile.title}
                      />
                    ))}
                  </BigPictureGallery>
                </div>
                <div className="alpha">
                <BigPictureGallery loop>
                    {staffDataMizzen.map(tile => (
                      <img
                        src={tile.img}
                        zoomedSrc={tile.imgLg}
                        caption={tile.caption}
                        alt={tile.title}
                      />
                    ))}
                  </BigPictureGallery>
                </div>
              </div>
              <svg className="clip-svg">
                <defs>
                  <clipPath
                    id="clip-diamond-demo"
                    clipPathUnits="objectBoundingBox"
                  >
                    <polygon points="0.5 0, 1 0.5, 0.5 1, 0 0.5"></polygon>
                  </clipPath>
                </defs>
              </svg>
            </SectionStyle>
          </Grid>
        </Grid>
      </div>
    </LazyLoad>
  );
}

const SectionStyle = styled.div`
  .alpha {
    img:not(:first-of-type) {
      display: none;
    }
  }
  position: relative;
  .diamond {
    width: 356px;
    height: 356px;
    margin: 1em auto;
    position: relative;
    .alpha {
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      clip-path: url(#clip-diamond-demo);
      height: 178px;
      width: 178px;
      position: absolute;
      z-index: 1;
      background: linear-gradient(
        hsla(77, 18%, 57%, 1) 0%,
        hsla(199, 34%, 43%, 1) 100%
      );
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        filter: grayscale(1);
        &:hover {
          filter: grayscale(0);
        }
      }
      h2 {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 10000;
      }
      &:nth-of-type(1) {
        top: 0;
        left: 90px;
      }
      &:nth-of-type(2) {
        top: 90px;
        left: 0;
      }
      &:nth-of-type(3) {
        top: 90px;
        left: 180px;
      }
      &:nth-of-type(4) {
        top: 180px;
        left: 90px;
      }
      &:nth-of-type(5) {
        top: 180px;
        left: 270px;
      }
      &:nth-of-type(6) {
        top: 180px;
        left: -90px;
      }
    }
  }
  @media (min-width: 220px) and (max-width: 560px) {
    transform: scale(0.7);
  }
`;

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <LazyLoad>
      <SectionStyle>
        <svg
          className="angle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path fill="#FFF" fillRule="nonzero" d="M1440 0L721.4 68 0 0z" />
        </svg>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" id="form-dialog-title">
              Work with us
            </Typography>
            <Fab
              variant="extended"
              size="large"
              color="default"
              aria-label="contact"
              onClick={handleClickOpen}
            >
              Let’s Talk
            </Fab>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullScreen={fullScreen}
        >
          <FormStyle>
            <DialogContent>
              <Typography variant="h2">
                We look forward to connecting with you!
              </Typography>
              <form
                name="contact"
                id="contact"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
                className="email-form"
              >
                <input type="hidden" name="form-name" value="contact" />
                <Grid container justify="center">
                  <Grid item xs={8}>
                    <p className="hidden">
                      <label>
                        Don’t fill this out: <input name="bot-field" />
                      </label>
                    </p>
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="name"
                      label="Name"
                      name="name"
                      type="text"
                      fullWidth
                      required
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="email"
                      label="Email Address"
                      name="email"
                      type="text"
                      fullWidth
                      required
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="phone"
                      label="Phone"
                      type="phone"
                      name="phone"
                      required
                      fullWidth
                    />
                    <TextField
                      variant="outlined"
                      margin="dense"
                      id="message"
                      label="Message"
                      name="message"
                      placeholder=""
                      multiline
                      rows="4"
                      fullWidth
                      required
                    />
                  </Grid>
                </Grid>
                <p>
                  <Fab
                    variant="extended"
                    size="large"
                    color="primary"
                    aria-label="Send"
                    type="submit"
                    className="submit"
                    fullWidth
                  >
                    Send
                  </Fab>
                </p>
                <p>
                  <Button onClick={handleClose} aria-label="Cancel">
                    Cancel
                  </Button>
                </p>
              </form>
            </DialogContent>
          </FormStyle>
        </Dialog>
        <svg
          className="angleUp"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 68"
        >
          <path
            fill="hsla(77, 18%, 57%, 1)"
            fillRule="evenodd"
            d="M0 68h1439.5V0L719.7 68 0 0c-.7 0-.7 14 0 68z"
          />
        </svg>
      </SectionStyle>
    </LazyLoad>
  );
}

const SectionStyle = styled.div`
  position: relative;
  text-align: center;
  margin-top: -11em;
  padding-top: 18em;
  padding-bottom: 16em;
  margin-bottom: -2.25em;
  background: hsla(199, 34%, 43%, 1);
  * {
    z-index: 1;
  }
  h2 {
    margin: 1em 0 0;
    color: #fff;
  }
  h3 {
    color: #fff;
  }
  button.MuiButtonBase-root {
    margin: 2em 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url("https://ik.imagekit.io/9p7hacrol/staff/workwithus.jpg")
      no-repeat top center fixed;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    filter: grayscale(9) opacity(0.4);
  }
  .angleUp {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .angle {
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 4;
  }
`;
const FormStyle = styled.div`
  position: relative;
  text-align: center;
  background: hsla(199, 34%, 43%, 1);
  .MuiInputBase-root,
  .MuiFormLabel-root,
  .MuiButton-root {
    color: #fff !important;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url("../images/staff/dialog.jpg") no-repeat;
    background-size: cover;
    overflow: hidden;
    filter: grayscale(9) opacity(0.4);
  }
  .hidden {
    display: none;
  }
  h2 {
    margin: 1em 0 0;
    text-align: center;
    color: #fff;
  }
  p {
    text-align: center;
    color: #fff;
  }
  button {
    margin: 1em;
    &.submit {
      width: 18em;
    }
  }
`;

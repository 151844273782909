const endeavorData = [
  {
    img:
      "https://i.vimeocdn.com/video/1123441370-1d3ed847aaf11263f64a51a9ad3f0efa6bcd8fa346446eb2ef6b492cadd1223b-d_640",
    imgLg: "https://vimeo.com/542679253",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/4409244c-5507-44c3-a97e-31e721b48d2b.gif?ClientID=vimeo-core-prod&Date=1620234604&Signature=944b32ee0ec93be121ba2f1c8b1c17fda3730630",
    type: "vimeo",
    title: "2021",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/885445504-f2d134077ef1883230c2daedb051021d576cd97114c7b3c7441d0a0ace721d64-d_295x166",
    imgLg: "https://vimeo.com/412896272",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/aabba631-eee0-4ada-b440-0909380abbf8.gif?ClientID=vimeo-core-prod&Date=1620233804&Signature=0a972cca78e0044248242e71d3ea49936107812c",
    type: "vimeo",
    title: "2020",
    vendor: "",
    project: "Click to watch"
  },
  {
    img:
      "https://i.vimeocdn.com/video/804095097-f73ce5764b91acb332912d2ed890f1bc584d8353b84ec82488c543a7c2148157-d_295x166",
    imgLg: "https://vimeo.com/351686682",
    imgquick: "https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/cf495fc0-417d-4d0e-ad11-b7d5aa54fc72.gif?ClientID=vimeo-core-prod&Date=1620234446&Signature=ab33f5d10b48cfb063ffb9479b74ccc5dd0d4cd9",
    type: "vimeo",
    title: "2019",
    vendor: "",
    project: "Click to watch"
  }
];

export default endeavorData;

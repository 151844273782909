const dataJustin = [
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/justin-evers.jpg?tr=w-178,h-178,fo-auto",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/justin-evers.jpg",
    type: "image",
    title: "Justin Evers",
    caption:
      "<h1>Justin Evers</h1>Justin is a graduate of the University of Rhode Island, double majoring in both Film/Media and Journalism. Passionate about capturing moments of beauty, and storytelling, Justin has worked with a diverse group of clients over multiple years in the video industry. Justin is also an avid musician and outdoorsman. On weekends you can find him seeing one of his favorite bands live, in the mountains camping, hiking or snowboarding, or by the water fishing, boating, and hammocking."
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/10514357_1676749305884359_8170298907692229149_o.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/10514357_1676749305884359_8170298907692229149_o.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/12469619_1090797887627791_1448186115730773414_o.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/12469619_1090797887627791_1448186115730773414_o.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/30707576_2215818931792342_4572319378984402944_o.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/30707576_2215818931792342_4572319378984402944_o.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/929712280017_19a.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/929712280017_19a.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_1130.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_1130.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_5372.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_5372.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  },
  {
    img: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_5550.jpg?tr=w-288",
    imgLg: "https://ik.imagekit.io/9p7hacrol/staff/justin/img_5550.jpg",
    type: "image",
    title: "Justin Evers",
    caption: ""
  }
];

export default dataJustin;

import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import BigPicture from "react-bigpicture";
import styled from "styled-components";

export default function Mission() {
  return (
    <SectionStyle>
      <Grid container justify="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3">
            To understand and show the best of who you are and what you do.
          </Typography>
          <BigPicture src="https://vimeo.com/805567186" type="vimeo" noLoader>
            <Fab
              variant="extended"
              size="large"
              color="default"
              aria-label="watch"
            >
              Watch our Reel
            </Fab>
          </BigPicture>
        </Grid>
      </Grid>
    </SectionStyle>
  );
}

const SectionStyle = styled.div`
  margin-top: -2.25em;
  padding: 1em 0 2em;
  background: linear-gradient(
    hsla(199, 34%, 43%, 1) 0%,
    hsla(77, 18%, 57%, 1) 100%
  );
  h3 {
    color: #fff;
    margin-bottom: 0.5em;
  }
  button {
    margin-bottom: -4.25em;
  }
`;

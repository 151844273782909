import React from "react";
import BigPicture from "react-bigpicture";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import corporateData from "./data_corporate.js";
import academicData from "./data_academic.js";
import nonprofitData from "./data_nonprofit.js";
import endeavorData from "./data_endeavor.js";
import LazyLoad from "react-lazyload";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    flexWrap: "nowrap",
    width: "100vw",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)"
  },
  title: {
    color: theme.palette.primary.light
  }
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <div className={classes.root}>
      <LazyLoad>
        <Grid container justify="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Films</Typography>
            <Typography>
              Services include: films, photography, video, animation, motion graphics, editing, aerial filming, multi-screen livestreaming - admissions, capital campaigns, product work, branding
              films, corporate events & overviews.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SectionStyle>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="standard"
                aria-label="full width tabs example"
                centered={true}
              >
                <Tab label="Corporate" {...a11yProps(0)} />
                <Tab label="Academic" {...a11yProps(1)} />
                <Tab label="Non Profit" {...a11yProps(2)} />
                <Tab label="Previous Reels" {...a11yProps(3)} />
              </Tabs>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <GridList
                    className={classes.gridList}
                    cols={4.5}
                    spacing={26}
                  >
                    {corporateData.map(tile => (
                      <GridListTile key={tile.id}>
                        <BigPicture src={tile.imgLg} type={tile.type} noLoader>
                          <img src={tile.img} alt={tile.title} loading="lazy" />
                          <GridListTileBar
                            title={tile.title}
                            subtitle={tile.vendor}
                            titlePosition="bottom"
                          />
                          <span>{tile.project}</span>
                          <img
                            src={`${tile.imgquick}`}
                            alt={tile.title}
                            className="peekaboo"
                            loading="lazy"
                          />
                        </BigPicture>
                      </GridListTile>
                    ))}
                  </GridList>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <GridList
                    className={classes.gridList}
                    cols={4.5}
                    spacing={26}
                  >
                    {academicData.map(tile => (
                      <GridListTile key={tile.id}>
                        <BigPicture src={tile.imgLg} type={tile.type} noLoader>
                          <img src={tile.img} alt={tile.title} loading="lazy" />
                          <GridListTileBar
                            title={tile.title}
                            subtitle={tile.vendor}
                            titlePosition="bottom"
                          />
                          <span>{tile.project}</span>
                          <img
                            src={`${tile.imgquick}`}
                            alt={tile.title}
                            className="peekaboo"
                            loading="lazy"
                          />
                        </BigPicture>
                      </GridListTile>
                    ))}
                  </GridList>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <GridList
                    className={classes.gridList}
                    cols={4.5}
                    spacing={26}
                  >
                    {nonprofitData.map(tile => (
                      <GridListTile key={tile.id}>
                        <BigPicture src={tile.imgLg} type={tile.type} noLoader>
                          <img src={tile.img} alt={tile.title} loading="lazy" />
                          <GridListTileBar
                            title={tile.title}
                            subtitle={tile.vendor}
                            titlePosition="bottom"
                          />
                          <span>{tile.project}</span>
                          <img
                            src={`${tile.imgquick}`}
                            alt={tile.title}
                            className="peekaboo"
                            loading="lazy"
                          />
                        </BigPicture>
                      </GridListTile>
                    ))}
                  </GridList>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  <GridList
                    className={classes.gridList}
                    cols={3.5}
                    spacing={26}
                  >
                    {endeavorData.map(tile => (
                      <GridListTile key={tile.id}>
                        <BigPicture src={tile.imgLg} type={tile.type} noLoader>
                          <img src={tile.img} alt={tile.title} loading="lazy" />
                          <GridListTileBar
                            title={tile.title}
                            subtitle={tile.vendor}
                            titlePosition="bottom"
                          />
                          <span>{tile.project}</span>
                          <img
                            src={`${tile.imgquick}`}
                            alt={tile.title}
                            className="peekaboo"
                            loading="lazy"
                          />
                        </BigPicture>
                      </GridListTile>
                    ))}
                  </GridList>
                </TabPanel>
              </SwipeableViews>
            </SectionStyle>
          </Grid>
        </Grid>
      </LazyLoad>
    </div>
  );
}

const SectionStyle = styled.div`

  .MuiGridListTile-root {
    width: 18em !important;
  }
  .MuiGridListTileBar-root {
    background: none;
    z-index: 2;
    text-align: left;
    height: 100%;
    .MuiGridListTileBar-titleWrap {
      position: absolute;
      bottom: 1em;
      left: 0;
      width: 90%;
      z-index: 2;
      text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.8);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(
        hsla(0, 0%, 0%, 0) 0%,
        hsla(0, 0%, 0%, 0.002) 19%,
        hsla(0, 0%, 0%, 0.008) 34%,
        hsla(0, 0%, 0%, 0.021) 47%,
        hsla(0, 0%, 0%, 0.042) 56.5%,
        hsla(0, 0%, 0%, 0.075) 65%,
        hsla(0, 0%, 0%, 0.126) 73%,
        hsla(0, 0%, 0%, 0.294) 80.2%,
        hsla(0, 0%, 0%, 0.378) 86.1%,
        hsla(0, 0%, 0%, 0.482) 91%,
        hsla(0, 0%, 0%, 0.541) 95.2%,
        hsla(0, 0%, 0%, 0.638) 98.2%,
        hsla(0, 0%, 0%, 0.7) 100%
      );
      opacity: 1;
    }
  }
  .MuiGridListTile-tile {
    border-radius: 0.5em;
    overflow: hidden;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
    height: 11.125em;
    position: relative;
    &:hover {
      .peekaboo {
        display: block;
      }
      span {
        display: block;
      }
    }
  }
  .MuiGridListTileBar-subtitle {
    width: 64%;
  }
  .bigpicture {
    img {
      box-sizing: border-box;
      height: 5vh;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      z-index: 1;
    }
    span {
      position: absolute;
      bottom: 1.4em;
      right: 1.5em;
      z-index: 5;
      color: #fff;
      font-size: 0.625em;
      text-transform: uppercase;
      display: none;
    }
  }
  .peekaboo {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    height: inherit;
    width: inherit;
    z-index: 100;
    transform: translateX(0%);
  }
  @media (min-width: 220px) and (max-width: 560px) {
    .MuiGridList-root {
      flex-direction: column !important;
      justify-content: flex-start;
    }
    .MuiGridListTile-root {
      margin: 0 auto;
      height: 178px;
    }
  }
  #full-width-tabpanel-3 {
    & .MuiGridList-root  {
      justify-content: center !important;
    }
  }
`;

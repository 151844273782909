const nonprofitData = [
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_090118_sail1_selects-77.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_090118_sail1_selects-77.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_080319_selects-94.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_080319_selects-94.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_090918_selects-173.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_090918_selects-173.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_northshore_071419_sail2_selects-102.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_northshore_071419_sail2_selects-102.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_090918_selects-185.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/healingwinds_vt_090918_selects-185.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-38.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-38.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-52.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-52.jpg",
    type: "image",
    title: "",
    vendor: "MIT",
    cols: 2
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-54.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-54.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-83.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-83.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-98.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-98.jpg",
    type: "image",
    title: "",
    caption: ""
  },
  {
    img:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-101.jpg",
    imgLg:
      "https://ik.imagekit.io/9p7hacrol/photos/nonprofit/janrosendaal_mashneemit_may2019-101.jpg",
    type: "image",
    title: "",
    caption: ""
  }
];

export default nonprofitData;
